import fetchCached from './fetchCached';

/**
 * @class autoServiceApi
 */
class autoServiceApi {
  /**
   * Endpoint no contexto do canal
   *
   * @param {string} suffixEndpoint Sufixo do endpoint da request
   * @returns {string} Retorna o endpoint completo
   */
  getChannelEndpoint(suffixEndpoint) {
    /**
     * @var {string} channelId ID do canal passado na meta tag do layout
     */
    let channelId = document.querySelector("meta[name='auto_api_channel']")
      .content;

    return `/channel/${channelId + suffixEndpoint}`;
  }

  /**
   * Lista os clones com base nos parâmetros
   *
   * @param {object[]} customParams Parâmetros adicionais para a request
   * @returns {Promisse}
   */
  getClones(customParams) {
    /**
     * @var {object[]} defaultParams Os parametros default em todas as requests de clones
     */
    let defaultParams = [
      { include: 'profile_image' },
      { "q[with_active_model]": true }
    ];

    return fetchCached(
      this.getChannelEndpoint('/clones'),
      defaultParams.concat(customParams)
    );
  }

  /**
   * Lista as offers com base nos parâmetros
   *
   * @param {object[]} customParams Parâmetros adicionais para a request
   * @returns {Promisse}
   */
  getOffers(customParams, skipCache = false) {
    /**
     * @var {object[]} defaultParams Os parametros default em todas as requests de clones
     */
    let defaultParams = [
      { "q[with_visible]": true }
    ];

    return fetchCached(
      this.getChannelEndpoint('/offers'),
      defaultParams.concat(customParams),
      3,
      skipCache
    );
  }

  /**
   * Lista os seminovos com base nos parâmetros
   */
  getUsedModels(customParams) {
    let defaultParams = [];

    return fetchCached(
      this.getChannelEndpoint('/used_models'),
      defaultParams.concat(customParams)
    );
  }

  /**
   * Lista as unidades com base nos parâmetros
   */
   getServedRegion(customParams) {
    let defaultParams = [];

    return fetchCached(
      this.getChannelEndpoint('/served_regions'),
      defaultParams.concat(customParams)
    );
  }

  /**
   * Lista as peças
   */
  getParts(customParams) {
    let defaultParams = [];

    return fetchCached(
      this.getChannelEndpoint('/parts'),
      defaultParams.concat(customParams)
    );
  }

  /**
   * Lista as vendas diretas por categoria
   */
  getDirectSales(customParams) {
    let defaultParams = [
      { "q[with_unexpired]": true }
    ];

    return fetchCached(
      this.getChannelEndpoint('/direct_sales'),
      defaultParams.concat(customParams)
    );
  }

  /**
   * Lista as acessorios
   */
  getAccessories(customParams) {
    let defaultParams = [];

    return fetchCached(
      this.getChannelEndpoint('/accessories'),
      defaultParams.concat(customParams)
    );
  }

  getCategoryDirectSales(customParams) {
    let defaultParams = []

    return fetchCached(
      this.getChannelEndpoint('/category_direct_sales'),
      defaultParams.concat(customParams)
    )
  }

  /**
   * Retorna as `options` disponíveis para selects de filtros
   * @return {Promise}
   */
  getSelectFilterOptions(customParams = []) {
    return fetchCached(this.getChannelEndpoint('/used_models_filter'), customParams);
  }

  getConsortia(customParams) {
    let defaultParams = []

    return fetchCached(
      this.getChannelEndpoint('/consortium'),
      defaultParams.concat(customParams)
    )
  }

  getServices(customParams) {
    const defaultParams = [];

    return fetchCached(
      this.getChannelEndpoint('/services'),
      defaultParams.concat(customParams),
    );
  }

  getSelectFilterOptionsV2(customParams = []) {
    return fetchCached(this.getChannelEndpoint('/stock_filter'), customParams);
  }
}

export default new autoServiceApi();
