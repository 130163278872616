import { Component } from 'preact';
import PropTypes from 'prop-types';
import validationFactory from '%/utils/validation';
import { registerField } from '%/utils/customForm';
import getUTM from '%/utils/utm';
import {
  FormWrapper,
  FormHeader,
  FormBody,
  FieldGroup,
  Input,
  Checkbox,
  Select,
  FormFooter,
  SubmitButton,
  ContactFieldGroup,
  InputFile,
  LocationGroupField,
  VersionElements,
} from '../FormComponents';
import FormMessageOverlay from '../../FormMessageOverlay';
import CustomFields from './CustomFields';

export default class CustomForm extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.validator = validationFactory(`#${this.props.formId}`);
  }

  async handleSubmit(e) {
    e.preventDefault();

    const invalidInputs = this.validator.validateAll(this.form);
    const formIsValid = invalidInputs.length === 0;

    if (this.props.switchableFields.showLocationField)
      this.validateLocationFields();

    if (!formIsValid) {
      return;
    }

    this.props.onStartSubmit();

    let params;

    try {
      params = await this.getFormData();
    } catch (err) {
      console.error(err);
      this.props.onStopSubmit();
      this.props.onErrorSubmit(true);
      this.props.onShowOverlay(true);
      return;
    }

    // Se o form for válido, chamamos a prop para gerenciar a submissão do form.
    this.props.handleSubmit(e, params).then(() => {
      this.setState({ ...this.initialState });
    });
  }

  async getFormData() {
    const fieldsState = window.store.CustomFormFields.getState().formData;
    const fieldsData = Object.keys(fieldsState).reduce((acc, fieldName) => {
      acc[fieldName] = fieldsState[fieldName].value;
      return acc;
    }, {});

    if (this.uploadPromise) {
      const url = await this.uploadPromise;
      if (url) {
        fieldsData.attachment_url = url;
      } else {
        throw new Error('API não retornou a url do arquivo');
      }
    }

    const dataFromProps = {
      product: this.props.product,
      product_id: this.props.cloneId,
      bait: this.props.bait,
      category: this.props.category,
      brand: this.props.brand,
      utmz: getUTM(),
    };

    if (this.props.unit) {
      dataFromProps.unit = this.props.unit;
    } else if (this.props.units.length === 1) {
      dataFromProps.unit = this.props.units[0].value;
    } else {
      dataFromProps.unit = this.state.unit;
    }

    return { ...dataFromProps, ...fieldsData };
  }

  validateLocationFields() {
    if (!this.state.city) {
      document
        .getElementsByName('city')[0]
        .parentElement.classList.add('is-invalid');
      if (!this.state.uf) {
        document
          .getElementsByName('uf')[0]
          .parentElement.classList.add('is-invalid');
      } else {
        document
          .getElementsByName('uf')[0]
          .parentElement.classList.remove('is-invalid');
      }
    }
  }

  render() {
    const {
      formId,
      onShowOverlay,
      errorSendingForm,
      isSubmittingForm,
      mainTitle,
      mainSubtitle,
      submitButtonText,
      switchableFields,
      linkPrivacyPolicy,
      showOverlay,
    } = this.props;

    return (
      <FormWrapper
        customClasses="conversion-form--custom-form"
        formId={formId}
        setRef={form => {
          this.form = form;
        }}
        handleSubmit={this.handleSubmit}
      >
        <FormMessageOverlay
          handleClose={() => onShowOverlay(false)}
          isVisible={showOverlay}
          type={errorSendingForm ? 'error' : 'success'}
          successMessage={this.props.successTitle}
          content={{
            success: {
              bottomMessage: this.props.successSubtitle,
            },
          }}
        />
        <FormHeader
          title={mainTitle}
          subtitle={
            mainSubtitle !== ''
              ? mainSubtitle
              : 'Preencha o formulário abaixo para receber o contato de um de nossos especialistas'
          }
        />
        <FormBody>
          <VersionElements
            variants={this.props.variants}
            showVariantSelect={this.props.switchableFields.showVariantSelect}
            showVariantImage={this.props.switchableFields.showVariantImage}
            showVariantPrice={this.props.switchableFields.showPrice}
            showPrice={this.props.switchableFields.showPrice}
            defaultVariant={this.props.defaultVariant}
          />
          <FieldGroup>
            <Input
              title="Nome"
              type="text"
              placeholder="Nome"
              showLabels
              required
              {...registerField('name', {
                validation: {
                  required: true,
                  minLength: 3,
                },
              })}
            />
            {switchableFields.showEmailField && (
              <Input
                title="E-mail"
                type="email"
                placeholder="E-mail"
                showLabels
                required
                {...registerField('email', {
                  validation: {
                    required: true,
                    validEmail: true,
                  },
                })}
              />
            )}
            {switchableFields.showPhoneField && (
              <Input
                title="Telefone"
                type="phone"
                placeholder="Telefone/Whatsapp"
                maxLength={15}
                showLabels
                required
                {...registerField('phone', {
                  validation: {
                    required: true,
                    validPhone: true,
                  },
                  mask: 'phone',
                })}
              />
            )}
            {switchableFields.showCpfField && (
              <Input
                title="CPF"
                type="text"
                placeholder="CPF"
                showLabels
                required
                maxLength={14}
                {...registerField('cpf', {
                  validation: {
                    required: true,
                    validCpf: true,
                  },
                  mask: 'cpf',
                })}
              />
            )}

            {switchableFields.showUploadField && (
              <div>
                <label className="form__field-label">Envie o seu arquivo</label>
                <InputFile />
              </div>
            )}

            {switchableFields.showLocationField && <LocationGroupField />}
            {switchableFields.showUnitField && (
              <Select
                options={this.props.units}
                label="Escolha a unidade:"
                placeholder="Selecione uma unidade"
                {...registerField('unit')}
              />
            )}
          </FieldGroup>

          <CustomFields customFormFields={this.props.customFormFields} />

          {switchableFields.showChooseContactMethod && <ContactFieldGroup />}
          {switchableFields.showDataPermissions && (
            <FieldGroup>
              <Checkbox
                label="Autorizo o uso de minhas informações pessoais para campanhas de marketing."
                checked
                customClasses="form__field-checkbox-data-permissions"
                {...registerField('dataPermissions')}
              />
            </FieldGroup>
          )}
          <FormFooter linkPrivacyPolicy={linkPrivacyPolicy} />
          <SubmitButton
            label={
              submitButtonText !== '' ? submitButtonText : 'ESTOU INTERESSADO'
            }
            isSubmitting={isSubmittingForm}
          />
        </FormBody>
      </FormWrapper>
    );
  }
}

CustomForm.defaultProps = {
  onShowOverlay: () => {},
  onErrorSubmit: () => {},
  onStartSubmit: () => {},
  onStopSubmit: () => {},
  handleSubmit: () => {},
  handleVariantChange: () => {},
  showOverlay: false,
  isSubmittingForm: false,
  errorSendingForm: false,
  mainTitle: null,
  mainSubtitle: null,
  successTitle: null,
  successSubtitle: null,
  submitButtonText: null,
  module: null,
  switchableFields: {
    showEmailField: true,
    showPhoneField: true,
    showCpfField: false,
    showUnitField: false,
    showUploadField: false,
    showLocationField: false,
    showChooseContactMethod: true,
    showDataPermissions: false,
    showVariantSelect: false,
    showVariantImage: false,
    showVariantPrice: false,
    showPrice: false,
  },
  dataPermissionsCustomText: null,
  linkPrivacyPolicy: null,
  product: null,
  channel: null,
  category: null,
  brand: null,
  units: [],
  unit: null,
  bait: null,
  version: null,
  customFormFields: [],
  variants: [],
  defaultVariant: '',
  versions: [],
};

CustomForm.propTypes = {
  onShowOverlay: PropTypes.func,
  onErrorSubmit: PropTypes.func,
  onStartSubmit: PropTypes.func,
  onStopSubmit: PropTypes.func,
  handleVariantChange: PropTypes.func,
  showOverlay: PropTypes.bool,
  isSubmittingForm: PropTypes.bool,
  errorSendingForm: PropTypes.bool,
  handleSubmit: PropTypes.func,
  formId: PropTypes.string.isRequired,
  mainTitle: PropTypes.string,
  mainSubtitle: PropTypes.string,
  successTitle: PropTypes.string,
  successSubtitle: PropTypes.string,
  submitButtonText: PropTypes.string,
  module: PropTypes.string,
  switchableFields: PropTypes.shape({
    showEmailField: PropTypes.bool,
    showPhoneField: PropTypes.bool,
    showCpfField: PropTypes.bool,
    showUnitField: PropTypes.bool,
    showUploadField: PropTypes.bool,
    showLocationField: PropTypes.bool,
    showChooseContactMethod: PropTypes.bool,
    showDataPermissions: PropTypes.bool,
    showVariantSelect: PropTypes.bool,
    showVariantImage: PropTypes.bool,
    showVariantPrice: PropTypes.bool,
    showPrice: PropTypes.bool,
  }),
  dataPermissionsCustomText: PropTypes.string,
  linkPrivacyPolicy: PropTypes.string,
  product: PropTypes.string,
  channel: PropTypes.string,
  category: PropTypes.string,
  brand: PropTypes.string,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  unit: PropTypes.string,
  bait: PropTypes.string,
  version: PropTypes.string,
  customFormFields: PropTypes.arrayOf(
    PropTypes.shape({
      field_type: PropTypes.string,
      title: PropTypes.string,
      placeholder_text: PropTypes.string,
      mask_type: PropTypes.string,
      attribute_name: PropTypes.string,
      field_options: PropTypes.string,
      is_required: PropTypes.bool,
      ordination: PropTypes.number,
    }),
  ),
  versions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  variants: PropTypes.array.isRequired,
};
