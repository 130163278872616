import { Component } from 'preact';
import PropTypes from 'prop-types';
import { makeItemOfferDisplay } from '../../../utils/makeItemOfferDisplay';

export default class UsedVehicleClassicCard extends Component {
  constructor(props) {
    super(props);

    this.itemOfferDisplay = makeItemOfferDisplay(
      this.props.oldPrice,
      this.props.price,
    );

    this.displaySealsList = this.displaySealsList.bind(this);
    this.technicalItems = this.technicalItems.bind(this);
  }

  displaySealsList() {
    return this.props.seals.slice(0, 3);
  }

  technicalItems() {
    const { km, exchange, fuelText } = this.props;
    if (!km && !exchange && !fuelText) return null;

    const items = [
      {
        label: 'Câmbio',
        value: exchange,
      },
      {
        label: 'Quilometragem',
        value: km && `${km} KM`,
      },
      {
        label: 'Combustível',
        value: fuelText,
      },
    ];

    return items;
  }

  render() {
    const {
      moduleLink,
      slug,
      badge,
      itemImage,
      name,
      model,
      seals,
      subtitle,
      isOnFirstPage,
    } = this.props;

    return (
      <div className="card-used-vehicles">
        <div className="card-used-vehicles__header">
          <div className="card-used-vehicles__badges">
            {badge && (
              <span className="badge--primary badge badge--pill">{badge}</span>
            )}
            {seals.length > 0 &&
              this.displaySealsList().map(seal => (
                <span className="badge--secondary badge badge--pill badge--seal">
                  {seal}
                </span>
              ))}
          </div>
          <a
            className="card-used-vehicles__image-link"
            href={`${moduleLink}/${slug}`}
          >
            <img
              className="card-used-vehicles__image"
              src={itemImage}
              alt={name}
              width="250"
              height="190"
              // eslint-disable-next-line react/no-unknown-property
              fetchpriority={isOnFirstPage ? 'high' : 'low'}
              loading={isOnFirstPage ? 'auto' : 'lazy'}
            />
          </a>
        </div>
        <div className="card-used-vehicles__content">
          <div
            className="card-used-vehicles__content-header"
            data-match-height="card-used-vehicles__content-header"
          >
            <p className="card-used-vehicles__title">{model}</p>
            <p className="card-used-vehicles__subtitle">{subtitle}</p>
          </div>

          {this.technicalItems() && (
            <ul
              className="card-used-vehicles__highlights-list"
              data-match-height="card-used-vehicles__highlights-list"
            >
              {this.technicalItems().map(
                item =>
                  !!item.value && (
                    <li className="card-used-vehicles__highlight-item">
                      <b>{item.label}: </b>
                      {item.value}
                    </li>
                  ),
              )}
            </ul>
          )}
        </div>

        <div className="card-used-vehicles__footer">
          <div
            data-match-height="list-used-models-card-triggers"
            className="card-used-vehicles__triggers"
          >
            {this.itemOfferDisplay.secondary_call && (
              <div className="card-used-vehicles__trigger">
                <s>{this.itemOfferDisplay.secondary_call}</s>
              </div>
            )}
            {this.itemOfferDisplay.main_call && (
              <div className="card-used-vehicles__title">
                {this.itemOfferDisplay.main_call}
              </div>
            )}
            {this.itemOfferDisplay.featured && (
              <div className="card-used-vehicles__trigger-value">
                {this.itemOfferDisplay.featured}
              </div>
            )}
          </div>
          <a
            href={`${moduleLink}/${slug}`}
            className="btn button button--block button--large card__cta button--primary card-used-vehicles__cta"
          >
            Estou interessado
          </a>
        </div>
      </div>
    );
  }
}

UsedVehicleClassicCard.defaultProps = {
  itemImage: {},
  slug: null,
  name: null,
  badge: null,
  price: null,
  oldPrice: null,
  subtitle: null,
  exchange: null,
  km: null,
  fuelText: null,
  model: null,
  isOnFirstPage: false,
  seals: [],
};

UsedVehicleClassicCard.propTypes = {
  moduleLink: PropTypes.string.isRequired,
  slug: PropTypes.string,
  itemImage: PropTypes.objectOf,
  badge: PropTypes.string,
  name: PropTypes.string,
  price: PropTypes.string,
  exchange: PropTypes.string,
  km: PropTypes.string,
  fuelText: PropTypes.string,
  model: PropTypes.string,
  oldPrice: PropTypes.string,
  subtitle: PropTypes.string,
  isOnFirstPage: PropTypes.bool,
  seals: PropTypes.arrayOf(),
};
