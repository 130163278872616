// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import {
  incrementFilter,
  setComponentsExhibition,
  setKeyword,
  setList,
  setPage,
} from '../../../../../stores/Filters/UsedVehicles/FilterV2';
import { mapFiltersV2 } from '../../../../../utils/filters/usedVehicles/mapFiltersV2';
import { applyURLSearchParams } from '../../../../../utils/filters/usedVehicles/applyURLSearchParams';

class SearchTerm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      keyword: '',
    };

    this.handleClickSearch = this.handleClickSearch.bind(this);
    this.handleKeywordChange = this.handleKeywordChange.bind(this);
    this.recoverKeywordState = this.recoverKeywordState.bind(this);
  }

  componentDidMount() {
    this.recoverKeywordState();
    this.watchStore();
  }

  async handleClickSearch(event) {
    event.preventDefault();

    const { keyword } = this.state;
    const { fieldKeywordFilter } = this.props;

    setKeyword(keyword);
    incrementFilter({
      [fieldKeywordFilter]: keyword,
    });

    setPage(1);
    await setList();

    this.props.onClickSearch();
    setComponentsExhibition({
      results: true,
    });

    if (this.props.shouldSetURLSearchParams) applyURLSearchParams({ keyword });
  }

  handleKeywordChange(event) {
    const keyword = event.target.value;

    this.setState(oldState => ({
      ...oldState,
      keyword,
    }));
  }

  recoverKeywordState() {
    const { keyword } = window.store.FilterV2.getState();

    this.setState(oldState => ({
      ...oldState,
      keyword,
    }));
  }

  watchStore() {
    window.store.FilterV2.watch(state => {
      this.setState(oldState => ({
        ...oldState,
        keyword: state.keyword,
      }));
    });
  }

  render() {
    const { keyword } = this.state;

    return (
      <div className="search-term">
        <button
          type="submit"
          className="search-term__btn"
          aria-label="Pesquisar"
          onClick={event => this.handleClickSearch(event)}
        >
          <i className="icon icon-search-b" />
        </button>
        <div className="search-term__wrapper">
          <input
            className="search-term__input"
            type="text"
            placeholder="O que está buscando?"
            onInput={this.handleKeywordChange}
            value={keyword}
          />
        </div>
      </div>
    );
  }
}

SearchTerm.defaultProps = {
  fieldKeywordFilter: mapFiltersV2.keyword,
  shouldSetURLSearchParams: false,
  onClickSearch: () => {},
};

SearchTerm.propTypes = {
  fieldKeywordFilter: PropTypes.string,
  shouldSetURLSearchParams: PropTypes.bool,
  onClickSearch: PropTypes.func,
};

export default SearchTerm;
