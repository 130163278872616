/* eslint-disable no-return-assign */
import FilterV2 from '../auto/stores/Filters/UsedVehicles/FilterV2';
import FilterV2Options from '../auto/stores/Filters/UsedVehicles/FilterV2Options';

(() => {
  // Adicione chaves:valor com os stores que serão atribuídos ao escopo
  // global
  const store = {
    FilterV2,
    FilterV2Options,
  };
  window.store = Object.assign(window.store || {}, {});
  Object.keys(store).forEach(c => (window.store[c] = store[c]()));
})();
