import { Component } from 'preact';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeItemOfferDisplay } from '../../../utils/makeItemOfferDisplay';

export default class MainUsedVehiclesCard extends Component {
  constructor(props) {
    super(props);

    this.itemOfferDisplay = makeItemOfferDisplay(
      this.props.oldPrice,
      this.props.price,
    );

    this.isPremiumVehicle = this.isPremiumVehicle.bind(this);
    this.technicalItems = this.technicalItems.bind(this);
  }

  isPremiumVehicle(price) {
    const { channelPremiumPrice } = this.props;
    let premiumPrice = 150000;

    if (channelPremiumPrice > 0) {
      premiumPrice = channelPremiumPrice;
    }

    return price >= premiumPrice;
  }

  technicalItems() {
    const { km, exchange, fuelText } = this.props;
    const items = [
      {
        label: 'Câmbio',
        value: exchange,
      },
      {
        label: 'Quilometragem',
        value: km && `${km} KM`,
      },
      {
        label: 'Combustível',
        value: fuelText,
      },
    ];

    return items;
  }

  render() {
    const {
      moduleLink,
      slug,
      badge,
      brand,
      id,
      itemImage,
      kind,
      name,
      model,
      modelYear,
      seals,
      subtitle,
      showTechnicalItems,
    } = this.props;

    return (
      <div
        className="card"
        data-brand={brand}
        data-model={model}
        data-slug={slug}
        data-year={modelYear}
      >
        <div data-model={id}>
          <div className="card__header">
            <div className="card__badges">
              {badge && (
                <div className="card__badge-item badge badge--pill badge--card-used-model">
                  {badge}
                </div>
              )}
              {seals.length > 0 &&
                seals
                  .slice(0, 3)
                  .map(seal => (
                    <div className="card__badge-item badge badge--used-model-seal-list badge--pill">
                      {seal}
                    </div>
                  ))}
            </div>
            <a href={`${moduleLink}/${slug}`} aria-label={name}>
              <div className="card__image card__image--extra-large">
                {itemImage.middle_image ? (
                  <div>
                    <img
                      className="card__image-item swiper-lazy"
                      data-src={itemImage.middle_image}
                      loading="lazy"
                      alt={name}
                    />
                    <div className="carousel-consortia__carousel-loading loading-component swiper-lazy-preloader">
                      <div>
                        <div />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className={classNames('card__image-value', {
                      'default-bg__motorcycle_middle-default': kind === 'Moto',
                      'default-bg__model-middle-default': kind !== 'Moto',
                    })}
                  />
                )}
              </div>
            </a>
          </div>
          <div className="card__content">
            <div className="card__title card__title--text-left">{name}</div>
            <div
              className="card__subtitle card__subtitle--text-left"
              data-match-height="carousel-used-model-card-subtitle"
            >
              {subtitle}
            </div>

            <hr className="card__separator" />

            {showTechnicalItems && (
              <div
                className="card__technical-items card__list list list--primary"
                data-match-height="carousel-used-model-list"
              >
                {this.technicalItems().map(
                  item =>
                    !!item.value && (
                      <li className="card__technical-item">
                        <b>{item.label}: </b>
                        {item.value}
                      </li>
                    ),
                )}
              </div>
            )}

            <hr className="card__separator" />
          </div>

          <div className="card__footer">
            <div
              data-match-height="list-used-models-card-triggers"
              className="card__triggers"
            >
              {this.itemOfferDisplay.secondary_call && (
                <div className="card__trigger">
                  <s>{this.itemOfferDisplay.secondary_call}</s>
                </div>
              )}
              {this.itemOfferDisplay.main_call && (
                <div className="card__title">
                  {this.itemOfferDisplay.main_call}
                </div>
              )}
              {this.itemOfferDisplay.featured && (
                <div className="card__trigger-value">
                  {this.itemOfferDisplay.featured}
                </div>
              )}
              <a
                href={`${moduleLink}/${slug}`}
                className="btn button button--block button--large card__cta button--primary"
              >
                Estou interessado
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MainUsedVehiclesCard.defaultProps = {
  itemImage: {},
  slug: null,
  kind: null,
  name: null,
  badge: null,
  exchange: '',
  km: '',
  fuelText: '',
  price: null,
  oldPrice: null,
  id: null,
  brand: null,
  modelYear: null,
  subtitle: null,
  model: null,
  channelPremiumPrice: null,
  seals: [],
  showTechnicalItems: true,
};

MainUsedVehiclesCard.propTypes = {
  moduleLink: PropTypes.string.isRequired,
  slug: PropTypes.string,
  itemImage: PropTypes.objectOf,
  badge: PropTypes.string,
  kind: PropTypes.string,
  name: PropTypes.string,
  exchange: PropTypes.string,
  km: PropTypes.string,
  fuelText: PropTypes.string,
  brand: PropTypes.string,
  id: PropTypes.string,
  modelYear: PropTypes.string,
  price: PropTypes.string,
  model: PropTypes.string,
  oldPrice: PropTypes.string,
  channelPremiumPrice: PropTypes.number,
  subtitle: PropTypes.string,
  seals: PropTypes.arrayOf(),
  showTechnicalItems: PropTypes.bool,
};
